<template>
  <div v-if="isPermission && isEdit">
    <div class="row" style="margin: 5px">
      <div class="col-md-10 col-9">
        <h2 class="font-weight-normal">
          {{ $t('createAdjustStock') }}
        </h2>
      </div>
    </div>
    <CCol md="12" lg="12">
      <CCard class="shadow-sm" style=" border-radius: 0.1rem;">
        <CCardBody>
          <div class="row">
            <div class="col-md-12 col-12">
              <h4 class="font-weight-normal">
                {{ $t('information') }}
              </h4>
            </div>
          </div>
          <br />
          <CRow>
          <CCol sm="1" col="3" class="text-center"><img src="/img/icon/store.png" style="width: 32px; height:32px;"/>
          </CCol>
            
            <CCol sm="11" col="9">
              <select id="createAdjustStockShopSelect" class="custom-select text-dark" v-model="shopObjectId" disabled>
                <option :id="'createAdjustStockShopOption' + shop.objectId" v-for="shop in shops" :key="shop.objectId" :value="shop.objectId">
                  {{ shop.shopName }} - {{ shop.branchName }}
                </option>
              </select>

              <div v-if="validateShop" class="text-danger">
                {{ validateShop }}
              </div>
            </CCol>
          </CRow>
          <br />
        </CCardBody>
      </CCard>
      <CCard class="shadow-sm" style=" border-radius: 0.1rem;">
        <CCardBody>
          <div class="row">
            <div class="col-md-6">
              <h4 class="font-weight-dark">
                {{ $t('adjustStockList') }}
              </h4>
              <p>
                {{ $t('adjustDetail') }}
              </p>
            </div>
            <div class="col-md-4 col-8 text-right">
              <CButton id="createAdjustStockImportButton" class="btn btn-outline-success btn-block" style="display: flex; align-items: center; justify-content: center;" @click="AddCheckStockModal = true">
                <i class="fi fi-rr-plus" style="margin-right: 8px;"></i>  {{ $t('importcheckStockDoc') }}
              </CButton>
            </div>
            <div class="col-md-2 col-4 text-right">
              <CButton id="createAdjustStockAddProductButton" class="btn btn-outline-success btn-block" style="display: flex; align-items: center; justify-content: center;" @click="AddProductModal = true">
                <i class="fi fi-rr-plus" style="margin-right: 8px;"></i>  {{ $t('addProduct') }}
              </CButton>
            </div>
          </div>
          <CRow v-if="items && items.length != 0" class="mt-3">
            <CCol sm="12" lg="12">
              <table id="createAdjustStockTable" style="white-space: nowrap;"
                class="table table-responsive table-bordered text-dark font-weight-normal">
                <thead>
                  <tr>
                    <th class="text-center text-dark font-weight-normal">#</th>
                    <th></th>
                    <th class="text-dark font-weight-normal">{{ $t('pluCode') }}</th>
                    <th class="text-dark font-weight-normal">{{ $t('productName') }}</th>
                    <th class="text-right text-dark font-weight-normal">{{ $t('beforeAdjust') }}</th>
                    <th class="text-right text-dark font-weight-normal">{{ $t('checkCount') }}</th>
                    <th class="text-right text-dark font-weight-normal">{{ $t('adjust') }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="index" :id="'createAdjustStockRow' + index">
                    <p style="display:none">
                      {{ (item.orderIndex = index) }}
                    </p>
                    <td class="text-center" style="width:2%">
                      <div style="margin-top:6px;">
                        {{ item.orderIndex + 1 }}
                      </div>
                    </td>
                    <td style="width: 5%;">
                      <div v-if="item.remoteImagePath" :style="{
    'background-image': `url('${item.remoteImagePath}'), url('${noImg}')`,
    'background-size': 'cover',
    'background-position': 'center center',
    'width': 'auto',
    'border-radius': '2px',
    'aspect-ratio': '1.3',
    'min-width': '50px',
  }"></div>
                      <div v-else style="border-radius: 2px" :style="{
    'aspect-ratio': '4 / 3',
    'min-width': '50px',
    backgroundColor: item.indexColor,
  }"></div>
                    </td>
                    <td>
                      <div style="margin-top:6px;">
                        {{ item.productPLU.PLUCode }}
                      </div>
                    </td>
                    <td style="width:40%">
                      <div style="margin-top:6px;">
                        {{ item.productPLU.name }}
                        ( {{ item.unit ? (item.unit.name || '-') : '-' }} / {{
    (item.productPLU.SKURatio || '1') }} )
                      </div>
                    </td>
                    <td style="width:10%">
                      <input class="form-control form-control-md text-right text-dark" v-model="item.previousOnhand"
                        disabled />
                    </td>
                    <td :id="'createAdjustStockRowQuantity'+ index" style="width:10%">
                      <input :id="'createAdjustStockRowQuantityInput'+ index" type="number" min="0" class="form-control form-control-md text-right text-dark"
                        v-model="item.quantity" />
                      <p class="text-danger" v-if="Number(item.quantity < 0) ||
    Number.isNaN(item.quantity)
    ">
                        {{ $t('dialogfound') }}
                      </p>
                    </td>
                    <p style="display:none">
                      {{
    (calculate =
      Number(item.quantity) - item.previousOnhand)
  }}
                    </p>
                    <td style="width:10%" v-if="calculate >= 0">
                      <input class="form-control form-control-md text-right text-success" :value="'+' + calculate"
                        disabled />
                    </td>
                    <td style="width:10%" v-else-if="calculate <= 0">
                      <input class="form-control form-control-md text-right text-danger" :value="calculate" disabled />
                    </td>
                    <td style="width:10%" v-else-if="isNaN(calculate) === true ">
                      <input class="form-control form-control-md text-right text-success" :value="0" disabled />
                    </td>
                    <td class="text-center" style="width:2%">
                      <CButton :id="'createAdjustStockRowRemoveButton' + index" color="danger" size="sm" @click="removeProduct(item.orderIndex)">
                        <CIcon name="cil-trash"></CIcon>
                      </CButton>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="text-danger" v-if="warning != ''">{{ warning }}</p>
              <p v-if="duplicateItem != ''" class="text-danger">
                {{ duplicateItem }}
              </p>
              <div class="form-group">
                <label class="font-weight-normal">
                  {{ $t('reason') }}
                </label>
                <textarea class="form-control" v-model="note" rows="5"></textarea>
              </div>
            </CCol>
          </CRow>
          <CRow v-else class="mt-3">
            <CCol sm="12" lg="12">
              <CDataTable>
                <template #no-items-view>
                  <div class="text-center my-5">
                    <h4 style="color: #ced2d8;">
                      {{ $t('noItem') }}
                    </h4>
                  </div>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
          <div v-if="validateItem" class="text-danger">
            {{ validateItem }}
          </div>
          <br />
        </CCardBody>
      </CCard>
    </CCol>

    <!-- Footer button -->
    <CCol>
      <div class="row justify-content-end" style="margin: 0">
        <CButton id="createAdjustStockConfirmButton" class="btn btn-success col-lg-2 col-4 font-weight-normal" style="margin-right: 15px;" @click="handlePopup"
          v-if="items.length != 0">
          {{ $t('confirm') }}
        </CButton>
        <CButton id="createAdjustStockConfirmDisabledButton" class="btn btn-success col-lg-2 col-4 font-weight-normal" style="margin-right: 15px;" v-else disabled>
          {{ $t('confirm') }}
        </CButton>
        <CButton id="createAdjustStockCancelButton" class="btn col-lg-2 col-4 shadow-sm font-weight-normal"
          style="background-color: white;font-weight: bold;" @click="$router.go(-1)">
          {{ $t('cancel') }}
        </CButton>       
      </div>
      <br />
    </CCol>

    <!-- Confirm save modal -->
    <CRow>
      <CModal id="createAdjustStockPopupModal" :show.sync="popupModal" color="success" :title="$t('confirmSaveDoc')" centered :footer="footer">
        <br />
        <h4 class="text-center">
          {{ $t('confirmAdjustStockMsg') }}
        </h4>
        <br />
        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="6">
              <CButton id="createAdjustStockPopupModalSaveButton" color="success" block v-if="loadingButton === true" v-on:click="formSubmit">
                {{ $t('save') }}
              </CButton>
              <CButton id="createAdjustStockPopupModalSaveDisabledButton" block color="success" v-else-if="loadingButton === false" disabled>
                <CSpinner color="white" size="sm" /> {{ $t('save') }}
              </CButton>
            </CCol>
            <CCol col="6">
              <CButton id="createAdjustStockPopupModalCancelButton" block color="light" @click="popupModal = false">
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </CRow>

    <CRow>
      <CModal id="createAdjustStockAddProductModal" :show.sync="AddProductModal" centered :footer="footer" size="lg" style="max-width: 915px;">
        <template #header-wrapper class="header-modal">
          <header class="modal-header header-modal">
            <h4 class="modal-title font-weight-normal">
              {{ $t('selectdProduct') }}
            </h4>
            <button id="createAdjustStockCloseButton" type="button" aria-label="Close" class="close" @click="AddProductModal = false">
              ×
            </button>
          </header>
        </template>
        <template #body-wrapper>
          <CCardBody>
            <CRow>
              <label class="col-sm-1 col-form-label text-left text-dark">
                {{ $t('search') }}
              </label>
              <CCol style="padding-right: 0;">
                <select id="createAdjustStockSearchBySelect" v-model="searchBy" class="custom-select no-border-radius-left">
                  <option id="createAdjustStockSearchOptionPLUCode" value="PLUCode">{{ $t('pluCode') }}</option>
                  <option id="createAdjustStockSearchOptionName" value="name">{{ $t('productName') }}</option>
                  <option id="createAdjustStockSearchOptionCategory" value="category">{{ $t('category') }}</option>
                </select>
              </CCol>
              <CCol class="pl-0 col-6">
                <template v-if="searchBy === 'category'">
                  <select id="createAdjustStockCategorySelect" v-model="selectedCategory" class="form-control text-dark no-border-radius-right" :placeholder="$t('selectCategory')">
                    <option selected value="">{{ $t('selectCategory') }}</option>
                    <option :id="'createAdjustStockCategoryOption' + category.objectId" v-for="category in dataCategory" :key="category.objectId" :value="category.objectId">
                      {{ category.name }}
                    </option>
                  </select>
                </template>
                <template v-else>
                  <input id="createAdjustStockSearchInput" class="form-control text-dark no-border-radius-right" v-model="keywordEntered" @input="searchProductPLU"
                    :placeholder="$t('searchProduct')" />
                </template>
              </CCol>
              <CCol class="p-0">
                <CButton id="createAdjustStockSearchButton" block color="success" v-if="loadingButton === true" v-on:click="searchProductPLU">
                  {{ $t('search') }}
                </CButton>
              </CCol>
              <CCol>
                <CButton id="createAdjustStockResetButton" class="btn btn-outline-secondary text-dark font-weight-normal"
                  style="font-weight: bold; width: inherit" v-on:click="reset">
                  {{ $t('reset') }}
                </CButton>
              </CCol>
            </CRow>
            <div style="max-height: 400px; overflow-y: auto;" class="mt-4">
              <CDataTable pagination :fields="fields" :items="autoCompleteResult" hover border v-model="autoCompleteResult"
                clickableRows @row-clicked="onSelectedAutoCompleteEvent" id="createAdjustStockDataTable">
                <template slot="selected-header">
                  <div class="custom-control custom-checkbox" style="padding-left: 2rem">
                    <input v-model="all" type="checkbox" @change="setAll()" class="custom-control-input success cursor"
                      id="checkbox" />
                    <label class="custom-control-label cursor" for="checkbox">
                    </label>
                  </div>
                </template>
                
                <template #selected="{ item, index }">
                  <td  :id="'createAdjustStockSelectedRow' + index" style="vertical-align: middle;">
                    <div class="custom-control custom-checkbox" style="padding-left: 2rem">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="item.selected"
                        @change="onSelectedAutoCompleteEvent(item)" :id="index" />
                      <label class="custom-control-label cursor" :for="index">
                      </label>
                    </div>
                  </td>
                </template>
                <template #name="{ item, index }">
                  <td style="vertical-align: middle; border-left: hidden" :for="index">
                    <img v-if="item.remoteImagePath" :src="item.remoteImagePath"
                      style="border-radius: 2px; aspect-ratio: 4 / 3" class="img-fluid" @error="noImgUrl" />
                    <div v-else style="border-radius: 2px" :style="{
    'aspect-ratio': '4 / 3',
    backgroundColor: item.indexColor,
  }"></div>
                  </td>
                </template>
                <template #nameproduct="{ item }">
                  <td class="text-dark" style="vertical-align: middle;border-left: hidden;">
                    {{ item.name }}
                  </td>
                </template>
                <template #categoryName="{ item }">
                  <td class="text-dark font-weight-normal" style="vertical-align: middle;border-left: hidden;">
                    {{ item.categoryName }}
                  </td>
                </template>
                <template #pluCode="{ item }">
                  <td class="text-dark" style="vertical-align: middle;border-left: hidden;">
                    {{ item.PLUCode }}
                  </td>
                </template>
                <template #price="{ item, index }">
                  <td :for="index" style="vertical-align: middle;border-left: hidden " v-if="item.ProductSKU.stdCost === '' ||
    item.ProductSKU.stdCost === undefined
    ">
                    ฿ 0
                  </td>
                  <td :for="index" style="vertical-align: middle;border-left: hidden " v-else>
                    ฿ {{ item.ProductSKU.stdCost }}
                  </td>
                </template>
                <template #onhandQty="{ item, index }">
                  <td class="text-right text-dark" :for="index" style="vertical-align: middle; border-left: hidden "
                    v-if="item.ProductPLUStock.onhandQty === '' ||
    item.ProductPLUStock.onhandQty === undefined
    ">
                    0
                  </td>
                  <td class="text-right" :for="index" style="vertical-align: middle; border-left: hidden " v-else>
                    <div v-if="floatValue(item.ProductPLUStock.onhandQty) >= 0" class="text-dark">
                      {{ item.ProductPLUStock.onhandQty }}
                    </div>
                    <div v-else class="text-danger">
                      {{ item.ProductPLUStock.onhandQty }}
                    </div>
                  </td>
                </template>
                <template #no-items-view>
                  <div class="text-center my-5">
                    <h4 style="color: #ced2d8;">
                      {{ $t('noItem') }}
                    </h4>
                  </div>
                </template>
              </CDataTable>
            </div>
          </CCardBody>
        </template>
        <template #footer-wrapper>
          <CRow class="justify-content-end col-md-12">
            <CCol col="12" lg="6" class="row">
              <div class="col-6">
                <CButton id="createAdjustStockFooterConfirmButton" block color="success" v-if="loadingButton === true" v-on:click="onCheckboxChange">
                  {{ $t('confirm') }}
                </CButton>
                <CButton id="createAdjustStockFooterConfirmDisabledButton" block color="success" v-else-if="loadingButton === false" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t('save') }}
                </CButton>
              </div>
              <div class="col-6">
                <CButton id="createAdjustStockFooterCancelButton" class="btn btn-outline-secondary text-dark font-weight-normal" style="width: inherit"
                  @click="AddProductModal = false">
                  {{ $t('cancel') }}
                </CButton>
              </div>              
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
    </CRow>
    <CRow>
      <CModal id="createAdjustStockAddCheckStockModal" :show.sync="AddCheckStockModal" centered :footer="footer" size="lg" style="max-width: 915px;">
        <template #header-wrapper class="header-modal">
          <header class="modal-header header-modal">
            <h4 class="modal-title font-weight-normal">
              {{ $t('selectCheckStockDoc') }}
            </h4>
            <CButton id="createAdjustStockCreateCheckStockButton" to="/inventory/check-stock/create" color="warning">
              <i class="fi fi-rr-plus" style="margin-right: 8px;"></i>  {{ $t('createCheckStock') }}
            </CButton>
            <!-- <button type="button" aria-label="Close" class="close" @click="AddCheckStockModal = false">
              ×
            </button> -->
          </header>
        </template>
        <template #body-wrapper>
          <CCardBody>
            <div style="max-height: 400px; overflow-y: auto;">
              <CDataTable id="createAdjustStockDataTable" :fields="checkStockFields" :items="checkStockItem" hover border v-model="checkStockItem"
                clickableRows @row-clicked="onSelectCheckStockDoc">

                <!-- <template slot="selected-header">
                  <div class="custom-control custom-checkbox" style="padding-left: 2rem">
                    <input v-model="all" type="checkbox" @change="setAll()" class="custom-control-input success cursor"
                      id="checkbox" />
                    <label class="custom-control-label cursor" for="checkbox">
                    </label>
                  </div>
                </template> -->
                <template slot="nameproduct-header">
                  <p></p>
                </template>
                <template #selected="{ item, index }">
                  <td :id="'createAdjustStockSelectedRow'+ index" style="vertical-align: middle; text-align: center;">
                    <div class="custom-control custom-checkbox " style="padding-left: 2rem">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="item.selected"
                        @change="onSelectCheckStockDoc(item)" :id="index" />
                      <label class="custom-control-label cursor" :for="index">
                      </label>
                    </div>
                  </td>
                </template>
                <template #name="{ item }">
                  <td class="text-dark" style="vertical-align: middle;border-left: hidden;">
                    {{ item.note }}
                  </td>
                </template>
                <template #no-items-view>
                  <div class="text-center my-5">
                    <h4 style="color: #ced2d8;">
                      {{ $t('noItem') }}
                    </h4>
                  </div>
                </template>
              </CDataTable>
            </div>
          </CCardBody>
        </template>
        <template #footer-wrapper>
          <CRow class="justify-content-end col-md-12">
            <CCol col="12" lg="6" class="row">
              <div class="col-6">
                <CButton id="createAdjustStockConfirmButton" block color="success" v-if="loadingButton === true" v-on:click="onCheckboxChange">
                  {{ $t('confirm') }}
                </CButton>
                <CButton id="createAdjustStockDisabledConfirmButton" block color="success" v-else-if="loadingButton === false" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t('confirm') }}
                </CButton>
              </div>
              <div class="col-6">
                <CButton id="createAdjustStockCancelButton" class="btn btn-outline-secondary text-dark font-weight-normal" style="width: inherit"
                  @click="checkStockItem = false">
                  {{ $t('cancel') }}
                </CButton>
              </div>
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
    </CRow>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from '@/services/local'
import util from '@/util/util'
import permis from '@/util/permission'
import pos from '@/services/local'

export default {
  data() {
    return {
      data: [],
      itemList: [],
      items: [],
      shop: this.$store.getters.shopObjectId,
      alert: false,
      searchBy: 'name',
      label: 'name',
      keyword: '',
      placeHolderInputText: 'พิมพ์ชื่อสินค้าที่ต้องการค้นหา .​.​.',
      autoCompleteResult: [],
      autoCompleteProgress: false,
      autoCompleteText: 'name',
      autoCompleteText2: 'PLUCode',
      autoCompleteText3: 'unitName',
      autoCompleteFieldId: 'alpha3Code',
      validateShop: '',
      validateItem: '',
      popupModal: false,
      footer: '',
      loadingButton: true,
      warning: '',
      duplicateItem: '',
      AddProductModal: false,
      AddCheckStockModal: false,
      keywordEntered: '',
      selectedItems: [],
      all: false,
      note: '',
      checkStockItem: [],
      dataCategory: [],
      selectedCategory: '',
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date', 'access']),
    isPermission() {
      return permis.findPermissionRead('inventory', '/inventory/adjust-stock')
    },
    isEdit() {
      return permis.findPermissionEdit('inventory', '/inventory/adjust-stock')
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
    fields() {
      return [
        {
          key: 'selected',
          label: this.$i18n.t('#'),
          _style: 'width:5%; vertical-align: middle;',
          _classes: 'text-left text-dark font-weight-normal',
        },
        {
          key: 'name',
          label: '',
          _style: 'width:7%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left text-dark font-weight-normal',
        },
        {
          key: 'nameproduct',
          label: this.$i18n.t('product'),
          _style: 'width:30%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left text-dark font-weight-normal',
        },
        {
          key: 'pluCode',
          label: this.$i18n.t('pluCode'),
          _style: 'width:25%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left text-dark font-weight-normal',
        },
        {
          key: 'categoryName',
          label: this.$i18n.t('category'),
          _style: 'width:23%; vertical-align: middle;',
          _classes: 'text-left font-weight-normal text-dark',
        },
        
        {
          key: 'onhandQty',
          label: this.$i18n.t('onhandQty'),
          _classes: 'text-right font-weight-normal  text-dark',
          _style: 'width:30%; vertical-align: middle;border-left: hidden',
        },
      ]
    },
    checkStockFields() {
      return [
        {
          key: 'selected',
          label: this.$i18n.t('selectd'),
          _style: 'width:10%; vertical-align: middle;',
          _classes: 'text-center text-dark font-weight-normal',
        },
        {
          key: 'name',
          label: this.$i18n.t('checkStockName'),
          _style: 'width:90%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left text-dark font-weight-normal',
        },
      ]
    },
  },
  created() {
    this.searchProductPLU()
    this.getCheckStock()
    this.getCategory()
    this.shop = this.shops.find((i) => i.objectId === this.shop)
  },
  methods: {
    getCategory() {
      const uid = `${localStorage.getItem('shopsUid')}`;
      const shopObjectId = this.shopObjectId;
      let params = {
        page: 1,
        limit: 1000,
      };

      pos
        .get('/api/v1.0/shop/' + uid + '/Category/data/' + shopObjectId, {
          params,
        })
        .then((res) => {
          let detail = []
          let data = res.data.data
          for (let i = 0; i < data.length; i++) {
            if (data[i].enabled === true) {
              detail.push({
                id: data[i].id,
                name: data[i].name || '-',
                objectId: data[i].objectId,
              })
            }
          }
          this.dataCategory = detail
          })
        .catch((error) => {
          console.error("Error fetching categories:", error);
        });
    },
    getCheckStock(page = 1) {
      this.loadingButton = false
      const uid = this.uid
      const shopObjectId = this.shopObjectId
      // const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      // const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        page: page,
        limit: 50,

      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      axios({
        url: '/api/v2.2/' + uid + '/CheckStockDocument',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          this.data = this.data.filter(item => item.shop.objectId === this.shopObjectId);
          
          this.loadingButton = true
          
          let checkStockDoc = this.data.map(item => {
            return { ...item, selected: false };
          });
          this.checkStockItem = checkStockDoc
          
        })
        .catch((error) => {
          console.log(error)
        })
    },
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE
    },
    setAll() {
      let select = this.selectedItems
      let data = this.autoCompleteResult
      // this.selectedItems = []
      if (this.all) {
        data.forEach(function (item) {
          item.selected = true
          select.push(item)
        })
      } else {
        data.forEach(function (item) {
          item.selected = false
          let i = select.findIndex((x) => x.objectId === item.objectId)
          if (i != -1) {
            select.splice(i, 1)
          }
        })
      }
      this.selectedItems = select
      this.autoCompleteResult = data
    },
    reset() {
      this.selectedItems = []
      this.all = false
      this.keywordEntered = ''
      this.searchBy = 'name'
      this.selectedCategory = ''
      this.searchProductPLU()
    },
    handlePopup() {
      if (this.shopObjectId === '') {
        this.popupModal = false
        this.validateShop = this.$i18n.t('selectShopPlease')
      } else {
        this.validateShop = ''
      }

      if (this.items.length === 0) {
        this.popupModal = false
        this.validateItem = this.$i18n.t('selectProductPlease')
      } else {
        this.validateItem = ''
      }

      if (this.items.length != 0 && this.shopObjectId != '') {
        this.popupModal = true
      }
    },
    removeProduct(orderIndex) {
      let items = this.items
      let newItems = items.filter((item) => item.orderIndex != orderIndex)
      this.items = newItems
      this.selectedItems.forEach((selectedItem) => {
        if (!newItems.some((item) => item.productPLUId === selectedItem.id)) {
          selectedItem.selected = false;
        }
      });
      this.selectedItems = this.selectedItems.filter((selectedItem) => selectedItem.selected);
    },
    onCheckboxChange() {
      let data = this.selectedItems;
      let items = this.items;
      let uid = this.uid;
      let shop = this.shop;
      let select = [];
      
      data.forEach(function (item, index) {
        let stdCost = 0;
        let previousOnhand = 0;
        let SKURatio = '-';

        if (item.selected) {
          select.push(item);

          if (
            item.ProductSKU.stdCost === undefined ||
            item.ProductSKU.stdCost === null
          ) {
            stdCost = 0;
          } else {
            stdCost = item.ProductSKU.stdCost;
          }

          if (item.SKURatio == undefined) {
            SKURatio = '-';
          } else {
            SKURatio = item.SKURatio;
          }
          if (
            item.ProductPLUStock !== undefined &&
            item.ProductPLUStock.onhandQty !== undefined // เพิ่มเงื่อนไขตรวจสอบความถูกต้องของ onhandQty
          ) {
            previousOnhand = item.ProductPLUStock.onhandQty;
          } else {
            previousOnhand = 0;
          }
          let checkCount = item.checkCount;
          let quantity = checkCount !== undefined ? checkCount : 0;
          if (items.length > 0) {
            let i = items.findIndex(
              (x) => x.productPLU.objectId === item.objectId
            );

            if (i == -1) {
              items.push({
                uid: uid,
                orderIndex: index,
                productPLU: {
                  // id: item.id,
                  objectId: item.objectId,
                  PLUCode: item.PLUCode,
                  name: item.name,
                },
                productPLUId: item.id,
                productSKU: {
                  id: item.ProductSKU.id,
                  objectId: item.ProductSKU.objectId,
                  SKUName: item.ProductSKU.SKUName,
                  SKUPrice: item.ProductSKU.SKUPrice,
                  leadTime: item.ProductSKU.leadTime,
                  shopId: item.shop.id,
                },
                productSKUId: item.ProductSKU.id,
                previousOnhand: previousOnhand,
                ProductPLUStock: item.ProductPLUStock,
                shop: {
                  id: shop.id,
                  objectId: shop.objectId,
                  shopName: shop.shopName,
                  branchName: shop.branchName,
                },
                shopId: shop.id,
                quantity: quantity,
                unit: {
                  name: item.unitName,
                },
                SKURatio: SKURatio,
                remoteImagePath: item.remoteImagePath,
                indexColor: util.generateColor(item.indexColor),
              });
            }
          } else {
            items.push({
              uid: uid,
              orderIndex: index,
              productPLU: {
                id: item.id,
                objectId: item.objectId,
                PLUCode: item.PLUCode,
                name: item.name,
              },
              productPLUId: item.id,
              productSKU: {
                id: item.ProductSKU.id,
                objectId: item.ProductSKU.objectId,
                SKUName: item.ProductSKU.SKUName,
                SKUPrice: item.ProductSKU.SKUPrice,
                leadTime: item.ProductSKU.leadTime,
                shopId: item.shop.id,
              },
              productSKUId: item.ProductSKU.id,
              previousOnhand: previousOnhand,
              ProductPLUStock: item.ProductPLUStock,
              shop: {
                id: shop.id,
                objectId: shop.objectId,
                shopName: shop.shopName,
                branchName: shop.branchName,
              },
              shopId: shop.id,
              quantity: quantity,
              unit: {
                name: item.unitName,
              },
              SKURatio: SKURatio,
              remoteImagePath: item.remoteImagePath,
              indexColor: util.generateColor(item.indexColor),
            });
          }
        } else {
          let index = items.findIndex(
            (x) => x.productPLU.objectId === item.objectId
          );
          if (index != -1) {
            items.splice(index, 1);
          }
        }
      });

      this.items = items;
      this.selectedItems = select;
      this.AddProductModal = false;
      this.AddCheckStockModal = false;
      this.all = false;
    },
    onSelectCheckStockDoc(item) {
      
      item.selected = !item.selected;
      let selectedDoc = [];
      let previousOnhand;
      if (item.items) {
        item.items.forEach(docItem => {
          const foundItem = this.autoCompleteResult.find(resultItem => resultItem.PLUCode === docItem.productPLU.PLUCode);

          if (foundItem) {
            previousOnhand = foundItem.ProductPLUStock.onhandQty;
          }

          let newItem = {
            PLUCode: docItem.productPLU?.PLUCode || '-',
            name: docItem.productPLU?.name || '-',
            ProductPLU: docItem.productPLU || {},
            ProductSKU: docItem.productSKU || {},
            id: docItem.productPLUId || '',
            objectId: docItem.productPLU?.objectId || '',
            unit: docItem.productPLU?.unit || {},
            unitName: docItem.productPLU?.unit?.name !== undefined 
                ? docItem.productPLU.unit.name 
                : (docItem.unit?.name !== undefined 
                    ? docItem.unit.name 
                    : '-'),
            ProductPLUStock: docItem.ProductPLUStock || {},
            SKURatio: docItem.productPLU?.SKURatio || 1,
            checkCount: docItem.quantity || 0,
            shop: docItem.shop || {},
            uid: this.uid || '',
            vatType: docItem.productSKU?.vatType || 'V',
            ProductPLUStock: {
              onhandQty: previousOnhand
            }
          };
          const matchedIndex = this.autoCompleteResult.findIndex(
            (autoItem) =>
              autoItem.objectId === docItem.productPLU?.objectId
          );

          if (matchedIndex !== -1) {
            const matchedItem = this.autoCompleteResult[matchedIndex];
            newItem.ProductSKU.stdCost = matchedItem.stdCost || matchedItem.ProductSKU?.stdCost || 0;
          } else {
            newItem.ProductSKU.stdCost = 0;
          }
          if (item.selected) {
            newItem.selected = true;
          }

          selectedDoc.push(newItem);
        });
      }

      this.selectedItems.push(...selectedDoc);
      
    },

    onSelectedAutoCompleteEvent(item) {
      item.selected = !item.selected
      // this.autoCompleteProgress = false
      if (item.selected) {
        this.selectedItems.push(item)
      } else {
        let i = this.selectedItems.findIndex(
          (x) => x.objectId === item.objectId
        )
        if (i != -1) {
          this.selectedItems.splice(i, 1)
        }
      }
      let countItem = this.autoCompleteResult.length
      let coutselect = 0
      this.autoCompleteResult.forEach(function (item) {
        if (item.selected) {
          coutselect = coutselect + 1
        }
      })
      if (countItem === coutselect) {
        this.all = true
      } else {
        this.all = false
      }
      
    },
    searchProductPLU() {
      if (this.shopObjectId === '') {
        this.validateShop = this.$i18n.t('selectShopPlease')
      } else {
        this.validateShop = ''
      }

      const uid = `${localStorage.getItem('shopsUid')}`
      let params = {}
      if (this.searchBy === 'category' && this.selectedCategory) {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          categoryObjectId: this.selectedCategory,
        }
      } else if (this.keywordEntered != '') {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          searchBy: this.searchBy,
          keyword: this.keywordEntered,
        }
      } else {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          searchBy: this.searchBy,
        }
      }
      this.label = this.searchBy
      let selectedItems = this.selectedItems
      // if (keywordEntered.length > 0) {
      const headers = { shopObjectId: this.$store.getters.shopObjectId }

      axios({
        url: '/api/v1.0/' + uid + '/getproductpluwithstock',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((response) => {
          this.alert = false
          let newData = []
          response.data.data.forEach(function (item) {
            if (selectedItems.length > 0) {
              let i = selectedItems.findIndex(
                (x) => x.objectId === item.objectId
              )
              if (i != -1) {
                item.selected = true
              } else {
                item.selected = false
              }
            } else {
              item.selected = false
            }
            if (item.unit == undefined) {
              item.unitName = '-'
            } else {
              if (item.unit.name == undefined) {
                item.unitName = '-'
              } else {
                item.unitName = item.unit.name
              }
            }
            const category = item.category || {}
            item.categoryName = category.name || '-'
            item.indexColor = util.generateColor(item.ProductSKU.indexColor)
            newData.push(item)
          })
          this.autoCompleteResult = newData

        })
        .catch((e) => {
          this.alert = true
          console.log(e)
        })
      // }
    },
    formSubmit(e) {
      this.loadingButton = false
      e.preventDefault()
      let itemIn = []
      let itemOut = []
      let data = []
      const uid = this.uid
      const plan = this.users.currentPlan

      const dataItem = { items: this.items }

      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)

      for (let i = 0; i < dataItem.items.length; i++) {
        delete dataItem.items[i].remoteImagePath
        delete dataItem.items[i].indexColor

        let calculate =
          dataItem.items[i].quantity - dataItem.items[i].previousOnhand
        if (calculate >= 0) {
          let newItemIn = dataItem.items[i]
          itemIn.push(newItemIn)
        } else {
          let newItemOut = dataItem.items[i]
          itemOut.push(newItemOut)
        }
      }
      
      const dataItemIn = {
        uid: this.uid,
        shop: {
          id: this.shop.id,
          objectId: this.shop.objectId,
          shopName: this.shop.shopName,
          branchName: this.shop.branchName,
        },
        shopId: this.shop.id,
        documentNo: this.documentNo,
        adjustDocType: 1,
        items: itemIn,
        note: this.note,
      }

      const dataItemOut = {
        uid: this.uid,
        shop: {
          id: this.shop.id,
          objectId: this.shop.objectId,
          shopName: this.shop.shopName,
          branchName: this.shop.branchName,
        },
        shopId: this.shop.id,
        documentNo: this.documentNo,
        adjustDocType: 2,
        items: itemOut,
        note: this.note,
      }

      if (itemIn.length != 0 && itemOut.length != 0) {
        data = [dataItemIn, dataItemOut]
      } else if (itemIn.length != 0 && itemOut.length === 0) {
        data = [dataItemIn]
      } else if (itemIn.length === 0 && itemOut.length != 0) {
        data = [dataItemOut]
      } else if (itemIn.length === 0 && itemOut.length === 0) {
        data = []
      }

      const headers = { shopObjectId: this.$store.getters.shopObjectId }

      if (this.shop.id != undefined && dataItem.items.length != 0) {
        axios({
          method: 'post',
          url: '/api/v1.0/' + uid + '/adjuststockdocument/save',
          params: { shopObjectId: this.shop.objectId, plan: plan },
          data: data,
          headers: headers,
        })
          .then(() => {
            setTimeout(() => {
              this.isSuccess = true
              this.$router.push('/inventory/adjust-stock')
              
            }, 5000)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    floatValue(value) {
      return parseFloat(value)
    },
  },
}
</script>

<style>
.no-border-radius-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.no-border-radius-right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

/* Hidden arrow  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="../../../assets/styles/inventory.css" scoped></style>
